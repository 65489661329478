import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ComponentProps, FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    control?: Control<any>;
};

export const ControlledDatePicker: FC<Props> = ({ name, control, label, ...textFieldProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                    slotProps={{
                        textField: {
                            ...textFieldProps,
                            id: `${name}-field`,
                            error: !!error,
                            helperText: (error as FieldError | undefined)?.message,
                        },
                        inputAdornment: { position: 'start' },
                    }}
                    label={label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format="dd/MM/yyyy"
                />
            )}
        />
    );
};
