import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledSelectOrganisations,
    FormGrid,
    Options,
    OptionsToggle,
    useSelectedOrganisation,
} from '../../../shared';
import { IRegistrationReportFilter } from '../../models/registration-report.model';

interface Props {
    filter: IRegistrationReportFilter;
    onChange: (filter: IRegistrationReportFilter) => void;
}

export const RegistrationsFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();

    const form = useForm<IRegistrationReportFilter>({ mode: 'onChange' });
    const { organisations: organisationOptions } = useSelectedOrganisation(); //organisations that I have access to
    const formValues = form.watch();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        startDate: { active: true, label: t('startDate') },
        endDate: { active: true, label: t('endDate') },
        organisations: {
            active: true,
            label: t('organisations'),
            hide: organisationOptions.length < 2,
        },
    });

    useEffect(() => {
        form.reset({ ...formValues, ...filter });
    }, [form, filter]);

    useEffect(() => {
        if (organisationOptions) {
            const organisationValues = organisationOptions?.map(({ id }) => id);
            form.setValue('organisations', organisationValues);
            onChange({ ...filter, organisations: organisationValues });
        }
    }, [organisationOptions, form.setValue]);

    useEffect(() => {
        const { endDate, organisations, startDate } = options;

        if (!startDate.active) form.setValue('startDate', undefined);

        if (!endDate.active) form.setValue('endDate', undefined);

        if (!organisations.active) form.setValue('organisations', organisationOptions?.map(({ id }) => id) || []);
    }, [options, form]);

    const updatedOptions =
        JSON.stringify(formValues, Object.keys(formValues).sort()) !==
        JSON.stringify(filter, Object.keys(formValues).sort());

    return (
        <Stack direction="row" spacing={1}>
            <OptionsToggle options={options} onChange={setOptions} />

            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                {options.startDate.active && (
                    <ControlledDatePicker
                        name="startDate"
                        label={t('startDate')}
                        control={form.control}
                        size="small"
                        className="filter"
                    />
                )}
                {options.endDate.active && (
                    <ControlledDatePicker
                        name="endDate"
                        label={t('endDate')}
                        control={form.control}
                        size="small"
                        className="filter"
                    />
                )}
                {options.organisations.active && organisationOptions.length > 1 && (
                    <ControlledSelectOrganisations name="organisations" label={t('organisations')} form={form} />
                )}
            </FormGrid>

            {updatedOptions && (
                <IconButton onClick={() => onChange(formValues)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};
