import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, date, object, ref, string } from 'yup';
import { supportTypesInfoSchema } from './support-type-info.validator';
import { baseSchema, ISaveSupportTypeInfo, referrerContactSchema } from '../../shared';

export function useIntakeCompletionSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                createSupportPeriod: boolean().required(),
                comment: string(),
                startDate: date()
                    .typeError(t('startDateInvalid'))
                    .required(t('startDateRequired'))
                    .when('createSupportPeriod', {
                        is: false,
                        then: (schema) => schema.transform(() => undefined).optional(),
                    }),
                endDate: date()
                    .typeError(t('endDateInvalid'))
                    .min(ref('startDate'), t('endBeforeStartDateError'))
                    .required(t('endDateRequired'))
                    .when('createSupportPeriod', {
                        is: false,
                        then: (schema) => schema.transform(() => undefined).optional(),
                    }),
                supportTypesInfo: array()
                    .of(supportTypesInfoSchema(t))
                    .test('unique', t('supportTypeIdMustBeUnique'), (value) =>
                        value && value?.length > 0
                            ? value.map((type) => type.supportTypeId).length ===
                              new Set(value.map((type) => type.supportTypeId))?.size
                            : true,
                    )
                    .required()
                    .when('createSupportPeriod', {
                        is: false,
                        then: (schema) => schema.transform(() => undefined).optional(),
                    }),
                referralOrganisation: baseSchema()
                    .shape({ id: string().required() })
                    .when('isNewContact', {
                        is: true,
                        then: (schema) => schema.required(t('referralOrganisationRequired')),
                    })
                    .when(['supportTypesInfo'], {
                        is: (supportTypesInfo: ISaveSupportTypeInfo[]) => supportTypesInfo.some(({ credit }) => credit),
                        then: (schema) => schema.required(t('requiredBecauseOfCredit')),
                    })
                    .nullable(),
                contact: referrerContactSchema().shape({ id: string().required() }).nullable(),
                isNewContact: boolean()
                    .when(
                        ['newContact.name', 'newContact.email', 'newContact.phone'],
                        ([name, email, phone], schema) =>
                            !name && !email && !phone
                                ? schema.test('Values-new-contact-empty', t('contactEmpty'), (value) => value === false)
                                : schema,
                    )
                    .required(),
                newContact: referrerContactSchema().nullable().optional(),
            }),
        [t],
    );
}
